import { Link } from 'react-router-dom'
import { Dropdown } from 'semantic-ui-react'
import { LinkMenuItem } from '../DropdownMenuItem'

export type Props = {
  label: string
  items: LinkMenuItem[]
}

export const HeaderMenuItem = ({ label, items }: Props) => (
  <Dropdown item simple text={label}>
    <Dropdown.Menu>
      {items.map(item => (
        <Dropdown.Item as={Link} key={item.label} to={item.to}>
          {item.label}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
)
