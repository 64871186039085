import { lazy } from 'react'
import { Route } from 'react-router-dom'
import { paths } from '../../paths'

const OrderDeliverabilty = lazy(async () => {
  const { OrderDeliverability } = await import(
    /* webpackChunkName: "OrderDeliverability" */ '../../../components/pages/private/OrderDeliverability'
  )

  return {
    default: OrderDeliverability,
  }
})

export const orderDeliverability: JSX.Element[] = [
  <Route element={<OrderDeliverabilty />} key={paths('/order-deliverability')} path={paths('/order-deliverability')} />,
]
