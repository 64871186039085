import { entries } from '../entries'

type OmitUtil = {
  <O extends Record<string, unknown>, K extends keyof O>(obj: O, keys: K[]): { [PK in Exclude<keyof O, K>]: O[PK] }
  <O extends Record<string, unknown>, K extends keyof O>(obj: O, ...keys: K[]): {
    [PK in Exclude<keyof O, K>]: O[PK]
  }
}

export const omit: OmitUtil = <O extends Record<string, unknown>, K extends keyof O>(obj: O, keys: K[]) =>
  entries(obj).reduce((acc, [key, value]) => {
    if (keys.includes(key as K)) {
      return acc
    }

    return { ...acc, [key]: value }
  }, {} as { [KK in keyof Omit<keyof O, K>]: O[KK] })
