import { lazy } from 'react'
import { Route } from 'react-router-dom'
import { paths } from '../../paths'

const ItemCommitments = lazy(async () => {
  const { ItemCommitments } = await import(
    /* webpackChunkName: "ItemCommitments" */ '../../../components/pages/private/ItemCommitments'
  )

  return {
    default: ItemCommitments,
  }
})

export const itemCommitments: JSX.Element[] = [
  <Route element={<ItemCommitments />} key={paths('/item-commitments')} path={paths('/item-commitments')} />,
]
