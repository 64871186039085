import { createContext, ReactNode, useCallback, useMemo, useReducer } from 'react'
import { IList, List } from '../../immutable/list'

type TAction = {
  type: 'close' | 'open'
  id: string
}

const reducer = (state: IList<string>, action: TAction) => {
  switch (action.type) {
    case 'close': {
      return state.filter(id => id !== action.id)
    }

    case 'open': {
      return state.find(id => id === action.id) ? state : state.push(action.id)
    }

    default: {
      return state
    }
  }
}

type TDialogsContext = {
  close: (id: string) => void
  has: (id: string) => boolean
  open: (id: string) => void
}

export const DialogsContext = createContext<TDialogsContext>({
  close: () => ({}),
  has: () => false,
  open: () => ({}),
})

type TProps = {
  children: ReactNode
}

export const DialogsProvider = ({ children }: TProps) => {
  const [dialogs, dispatch] = useReducer(reducer, List<string>([]))

  const close = useCallback(
    (id: string) =>
      dispatch({
        id,
        type: 'close',
      }),
    []
  )

  const has = useCallback((id: string) => dialogs.includes(id), [dialogs])

  const open = useCallback(
    (id: string) =>
      dispatch({
        id,
        type: 'open',
      }),
    []
  )

  const state = useMemo(() => ({ close, has, open }), [close, has, open])

  return <DialogsContext.Provider value={state}>{children}</DialogsContext.Provider>
}
