import { Either, isRight } from 'fp-ts/lib/Either'
import { useCallback } from 'react'
import { QueryFunction, QueryFunctionContext, useQuery, UseQueryOptions } from 'react-query'
import { FetchHttpResponse } from '../../packages/fetch'
import { ErrorType } from '../../types/error'
import { QueryKey } from '../../types/react-query'

type UseQueryEitherOptions<Key extends QueryKey, QueryData> = {
  key: Key
  fn: QueryFunction<Either<ErrorType, FetchHttpResponse<QueryData> & { type: 'ApiSuccess' }>, Key>
  config?: Omit<UseQueryOptions<QueryData, ErrorType, QueryData, Key>, 'queryFn' | 'queryKey'>
}
export const useQueryEither = <Key extends QueryKey, QueryData>({
  config = {},
  fn,
  key: queryKey,
}: UseQueryEitherOptions<Key, QueryData>) => {
  const queryFn = useCallback(
    async (context: QueryFunctionContext<Key>) => {
      const response = await fn(context)

      if (isRight(response)) {
        return response.right.payload
      }

      throw response.left
    },
    [fn]
  )

  return useQuery<QueryData, ErrorType, QueryData, Key>({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...config,
  })
}
