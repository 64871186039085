import { array } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import { concatQueryParams } from '../concatQueryParams'

type QueryParams = Record<
  string,
  boolean | number | string | (boolean | number | string)[] | Record<string, boolean | number | string>
>

export const withQuery =
  (queryParams: QueryParams = {}) =>
  (path: string) =>
    pipe([path, concatQueryParams(queryParams)], array.filter(Boolean), params => params.join('?'))
