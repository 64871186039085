import { ReactNode } from 'react'
import { Container } from 'semantic-ui-react'
import { paths } from '../../../../routes/paths'
import { Header } from './Header'
import { MenuItem } from './Header/MenuItem'

type Props = {
  children: ReactNode
}

const menus: MenuItem[] = [
  { type: 'link', to: '/', label: 'Home' },
  {
    type: 'menu',
    label: 'Impostazioni',
    items: [{ type: 'link', label: 'Utenti', to: paths('/settings/users') }],
  },
]

export const BaseLayout = ({ children }: Props) => {
  return (
    <div>
      <Header appName={'Phoenix ATP'} menus={menus} />
      <Container>{children}</Container>
    </div>
  )
}
