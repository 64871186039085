import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

type Props = {
  label: string
  to: string
}

export const HeaderLinkItem = ({ label, to }: Props) => (
  <Menu.Item as={Link} header to={to}>
    {label}
  </Menu.Item>
)
