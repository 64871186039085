import { Left } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { boolean } from 'io-ts'
import { array } from 'io-ts'
import { Errors, literal, strict, string } from 'io-ts'
import { weakUnion } from '../../../../packages/io-ts/weakUnion'
import { withDecoder } from '../../../../utils/withDecoder'

export const roleTypeCodec = weakUnion(
  [literal('ADMIN'), literal('AGENT'), literal('AREA_MANAGER'), literal('WAREHOUSE')],
  'unknown' as const
)

export const roles = roleTypeCodec.types.map(t => t.value)

export const fetchUserCodec = strict({
  createdBy: string,
  createDateTime: string,
  id: string,
  lastChangedBy: string,
  lastChangedDateTime: string,
  active: boolean,
  email: string,
  roles: array(roleTypeCodec),
})

export const fetchRoleDecoder = (payload: unknown) => pipe(payload, withDecoder(roleTypeCodec))
export const fetchUserDecoder = (payload: unknown) => pipe(payload, withDecoder(fetchUserCodec))

export type Role = Exclude<ReturnType<typeof fetchRoleDecoder>, Left<Errors>>['right']
export type User = Exclude<ReturnType<typeof fetchUserDecoder>, Left<Errors>>['right']
