import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { environment } from '../../configurations/environment'
import { LocalStorage } from '../../packages/storage/localStorage'
import { paths } from '../../routes/paths'
import { ErrorType } from '../../types/error'
import { omit } from '../../utils/functions/omit'
import { logging } from '../../utils/logging'

export const useApiErrorHandler = () => {
  const navigate = useNavigate()

  const logout = useCallback(async () => {
    const backofficeNode = await LocalStorage.getItem('phoenix-atp-store')

    await LocalStorage.setItem('phoenix-atp-store', omit(backofficeNode, ['authToken', 'role']))

    navigate(paths('/login'))
  }, [navigate])

  return useCallback(
    async (e: ErrorType) => {
      if (!environment.production) {
        logging.error(`Error type: ${e.type}, Error message: ${e.error.message}`)
      }

      switch (e.type) {
        case 'AxiosResponseError':
        case 'FetchResponseError': {
          if (!environment.production) {
            logging.error(e.error.message)
          }

          if ([401, 403].includes(e.error.response.status)) {
            await logout()
          }

          break
        }

        case 'AuthenticationError': {
          await logout()

          break
        }

        case 'AxiosUnknownError':
        case 'FetchUnknownError': {
          if (!environment.production) {
            logging.error(e.error.message)
          }

          break
        }

        default: {
          break
        }
      }
    },
    [logout]
  )
}
