import { Button, Container, Icon, Menu } from 'semantic-ui-react'
import { useLogout } from '../../../../../hooks/useLogout'
import { HeaderLinkItem } from './HeaderLinkItem'
import { HeaderMenuItem } from './HeaderMenuItem'
import { MenuItem } from './MenuItem'

type Props = {
  appName: string
  menus: MenuItem[]
}

type ItemRenderProps = {
  menus: MenuItem[]
}

const ItemRender = ({ menus }: ItemRenderProps) => (
  <>
    {menus.map(item => {
      switch (item.type) {
        case 'link':
          return <HeaderLinkItem key={item.label} label={item.label} to={item.to} />
        case 'menu':
          return <HeaderMenuItem items={item.items} key={item.label} label={item.label} />
        default:
          return <></>
      }
    })}
  </>
)

export const Header = ({ appName, menus }: Props) => {
  const handleLogout = useLogout()
  return (
    <Menu fixed="top" inverted>
      <Container>
        <Menu.Item header>{appName}</Menu.Item>
        <ItemRender menus={menus} />
        <Menu.Item position="right">
          <Button animated="vertical" inverted={true} onClick={handleLogout}>
            <Button.Content visible>
              <Icon name="sign-out" />
            </Button.Content>
            <Button.Content hidden>Esci</Button.Content>
          </Button>
        </Menu.Item>
      </Container>
    </Menu>
  )
}
