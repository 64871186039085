import { lazy } from 'react'
import { Route } from 'react-router-dom'
import { paths } from '../../paths'

const Login = lazy(async () => {
  const { Login } = await import(/* webpackChunkName: "Login" */ '../../../components/pages/public/Login')

  return {
    default: Login,
  }
})

export const login: JSX.Element[] = [<Route element={<Login />} key={paths('/login')} path={paths('/login')} />]
