import { lazy } from 'react'
import { Route } from 'react-router-dom'
import { paths } from '../../paths'

const ItemAvailability = lazy(async () => {
  const { ItemAvailability } = await import(
    /* webpackChunkName: "ItemAvailability" */ '../../../components/pages/private/ItemAvailability'
  )

  return {
    default: ItemAvailability,
  }
})

export const itemAvailability: JSX.Element[] = [
  <Route element={<ItemAvailability />} key={paths('/item-availability')} path={paths('/item-availability')} />,
]
