import { reduce } from 'fp-ts/Array'
import { pipe } from 'fp-ts/lib/function'

type Split<Text extends string, Separator extends string> = Text extends ''
  ? []
  : Text extends `${infer SubString}${Separator}${infer Rest}`
  ? [SubString, ...Split<Rest, Separator>]
  : Text extends `${infer RestString}`
  ? [RestString, ...Split<'', Separator>]
  : []

type FilterParams<Params> = Params extends [infer Param, ...infer OtherParams]
  ? Param extends `:${infer Name}`
    ? [Name, ...FilterParams<OtherParams>]
    : FilterParams<OtherParams>
  : []

type SplitEndpoint<Endpoint extends string> = Split<Endpoint, '/'>

type ExtractParams<Endpoint extends string> = FilterParams<SplitEndpoint<Endpoint>>

export const withParams = <Path extends string>(
  path: Path,
  params: Record<ExtractParams<Path>[number], boolean | number | string>
) =>
  pipe(
    params,
    Object.entries,
    reduce(path as string, (routeAcc, [param, value]) => routeAcc.replace(`:${param}`, `${value}`))
  )
