import * as B from 'fp-ts/lib/boolean'
import * as E from 'fp-ts/lib/Either'
import * as F from 'fp-ts/lib/function'
import * as P from 'fp-ts/lib/pipeable'
import * as TE from 'fp-ts/lib/TaskEither'
import { Errors } from 'io-ts'
import { useCallback } from 'react'
import { useConfig } from '../../context/Config'
import { fetch } from '../../packages/fetch'
import { HttpMethod, HttpStatus } from '../../types/fetch'
import { getApiAuthToken } from '../../utils/getApiAuthToken'
import { useApiErrorHandler } from '../useApiErrorHandler'

type Body = BodyInit | Record<string, any>

type Param<R> = {
  auth?: boolean
  body?: Body
  decoder?: (payload: unknown) => E.Either<Errors, R>
  headers?: HeadersInit
  endpoint: string
  params?: Record<string, string | number | boolean>
  query?: Record<
    string,
    boolean | number | string | (boolean | number | string)[] | Record<string, boolean | number | string>
  >
  method: HttpMethod
}

export type DecodedHttpResponse<R> = {
  headers?: Headers
  payload: R
  status: HttpStatus
}

export const useFetch = () => {
  const { baseUrl } = useConfig()
  const apiErrorHandler = useApiErrorHandler()

  return useCallback(
    <R>({
      auth,
      body,
      decoder = (payload: unknown) => E.right(F.identity(payload as R)),
      endpoint,
      headers = {},
      method,
      params = {},
      query = {},
    }: Param<R>) =>
      P.pipe(
        !!auth,
        B.fold(
          () => TE.right(''),
          () => getApiAuthToken
        ),
        TE.chainW(token => {
          const currentHeaders = new Headers(headers)

          if (token) {
            currentHeaders.set('Authorization', `Bearer ${token}`)
          }

          return P.pipe(
            () =>
              fetch({
                body,
                decoder,
                endpoint: `${baseUrl}${endpoint}`,
                headers: currentHeaders,
                method,
                params,
                query,
              }),
            TE.fold(
              l => async () => {
                await apiErrorHandler(l)

                return E.left(l)
              },
              r => async () => {
                return E.right(r)
              }
            )
          )
        })
      )(),
    [apiErrorHandler, baseUrl]
  )
}
