export type Route =
  | '*'
  | '/'
  | '/login'
  | '/settings/users'
  | '/order-deliverability'
  | '/order-deliverability/?order-number=:orderNumber'
  | '/item-commitments'
  | '/item-commitments/?item-code=:itemCode&variant-code=:variantCode&order-number=:orderNumber'
  | '/item-availability'
  | '/item-availability/?item-code=:itemCode'

export const paths = <SelectedRoute extends Route>(p: SelectedRoute) => p
