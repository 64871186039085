import { ReactNode } from 'react'
import { createGlobalStyle } from 'styled-components'

const Style = createGlobalStyle`
  html, body {
    background: #f7f7f7 !important;
    width: 100%;
    padding-top: 2em;
  }
`

type Props = {
  children: ReactNode
}

export const GlobalStyle = ({ children }: Props) => (
  <>
    <Style />
    {children}
  </>
)
