import * as io from 'io-ts'
import { array } from 'io-ts'
import { uuid } from '../../../../packages/io-ts/uuid'
import { roleTypeCodec } from '../../../users/fetchUser/codec'

export const selfMeCodec = io.strict({
  roles: array(roleTypeCodec),
  id: uuid,
  email: io.string,
})
