import * as E from 'fp-ts/Either'
import * as F from 'fp-ts/function'
import * as P from 'fp-ts/pipeable'
import { Errors } from 'io-ts'
import { PathReporter } from 'io-ts/lib/PathReporter'

import { HttpMethod, HttpStatus } from '../../types/fetch'
import { withParams } from '../../utils/http/withParams'
import { withQuery } from '../../utils/http/withQuery'
import { FetchUnknownError } from './errors/ApiFetchError'
import { FetchResponseError } from './errors/ApiResponseError'

type Body = BodyInit | Record<string, any>

const shouldLeftBodyUntouched = (body?: Body): body is BodyInit =>
  body === null ||
  typeof body === 'undefined' ||
  body instanceof FormData ||
  body instanceof ReadableStream ||
  body instanceof Blob ||
  body instanceof ArrayBuffer ||
  body instanceof URLSearchParams

const getContentType = (body?: Body) => {
  if (!(body instanceof FormData)) {
    return { 'Content-Type': 'application/json' }
  }

  return null
}

const mergeHeaders = (...headers: Headers[]) =>
  headers.reduce((acc, currentHeaders) => {
    currentHeaders.forEach((value, key) => {
      acc.set(key, value)
    })

    return acc
  }, new Headers())

type Param<R> = {
  auth?: boolean
  body?: Body
  decoder?: (payload: unknown) => E.Either<Errors, R>
  headers?: HeadersInit
  endpoint: string
  params?: Record<string, string | number | boolean>
  query?: Record<
    string,
    boolean | number | string | (boolean | number | string)[] | Record<string, boolean | number | string>
  >
  method: HttpMethod
}

export type FetchError =
  | { error: FetchUnknownError; type: 'FetchUnknownError' }
  | { error: FetchResponseError; type: 'FetchResponseError' }

export type FetchHttpResponse<R> = {
  headers?: Headers
  payload: R
  status: HttpStatus
}

export const fetch = async <R>({
  body,
  decoder = (payload: unknown) => E.right(F.identity(payload as R)),
  endpoint,
  headers = {},
  method,
  params = {},
  query = {},
}: Param<R>): Promise<E.Either<FetchError, FetchHttpResponse<R> & { type: 'ApiSuccess' }>> => {
  try {
    const composedEndpoint = P.pipe(withParams(endpoint, params), withQuery(query))

    const interpolatedBody = ['GET', 'HEAD'].includes(method)
      ? undefined
      : {
          body: shouldLeftBodyUntouched(body) ? body : JSON.stringify(body),
        }

    const contentType = getContentType(body)

    const response = await window.fetch(composedEndpoint, {
      cache: 'no-store' as const,
      credentials: 'same-origin' as const,
      headers: mergeHeaders(
        new Headers({ Accept: 'application/json' }),
        new Headers({ ...contentType }),
        new Headers(headers)
      ),
      method,
      ...interpolatedBody,
    })

    if (response.status > 300) {
      return E.left({
        error: new FetchResponseError(`Status code: ${response.status}`, response),
        type: 'FetchResponseError',
      })
    }

    const decodedPayload = decoder(await response.json())

    if (E.isLeft(decodedPayload)) {
      return E.left({
        error: new FetchResponseError(PathReporter.report(decodedPayload).join('\n'), response),
        type: 'FetchResponseError',
      })
    }

    return E.right({
      headers: response.headers,
      payload: decodedPayload.right,
      status: response.status as HttpStatus,
      type: 'ApiSuccess',
    })
  } catch (e) {
    return E.left({
      error: new FetchUnknownError(`Fetch is failed for some unknown reasons ${e}`),
      type: 'FetchUnknownError',
    })
  }
}

export type FetchResponse = ReturnType<typeof fetch> extends Promise<infer U> ? U : never
