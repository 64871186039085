import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../../../context/Auth'
import { paths } from '../../../../routes/paths'

type Props = {
  children: ReactNode
}

export const AuthGuard = ({ children }: Props) => {
  const {
    state: { authorized },
  } = useAuth()

  const location = useLocation()

  if (!authorized && location.pathname !== paths('/login')) {
    return <Navigate to={paths('/login')} />
  }

  if (authorized && location.pathname === paths('/login')) {
    return <Navigate to={paths('/')} />
  }

  return <>{children}</>
}
