import { Container, Grid, Segment } from 'semantic-ui-react'

export const NoMatch = () => (
  <Grid style={{ height: '100vh' }} textAlign="center" verticalAlign="middle">
    <Grid.Column>
      <Container text>
        <Segment size="massive" type="display">
          Oh, no. Ti sei perso?
        </Segment>
      </Container>
    </Grid.Column>
  </Grid>
)
