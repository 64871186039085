import { createContext, Dispatch, ReactNode, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useSelf } from '../../api/self'
import { SelfMe } from '../../api/self/me'
import { FallbackLoader } from '../../components/general/layout/FallbackLoader'
import { useQueryEither } from '../../hooks/useQueryEither'
import { LocalStorage } from '../../packages/storage/localStorage'

type State = {
  authorized: boolean
  token: string
  me?: SelfMe
}

type Action =
  | {
      type: '@auth/set'
      payload: State
    }
  | {
      type: '@auth/set-user'
      payload: { authorized: boolean; me: SelfMe }
    }
  | {
      type: '@auth/unauthorize'
    }

type Context = {
  dispatch: Dispatch<Action>
  state: State
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case '@auth/set': {
      return action.payload
    }

    case '@auth/set-user': {
      return { ...state, me: action.payload.me, authorized: action.payload.authorized }
    }

    case '@auth/unauthorize': {
      return {
        authorized: false,
        token: '',
        me: undefined,
      }
    }

    default: {
      return state
    }
  }
}

const initialState = { authorized: false, token: '', me: undefined }

const AuthContext = createContext<Context>({ dispatch: () => ({}), state: initialState })

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [initialized, setInitialized] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const self = useSelf()

  const { data, isSuccess, isLoading } = useQueryEither({
    config: {
      refetchOnReconnect: false,
      retry: false,
    },
    fn: () => self.fetchMe(),
    key: ['me'],
  })

  useEffect(() => {
    setInitialized(currentInitialized => {
      if (isLoading) {
        return false
      }

      if (isSuccess && !currentInitialized) {
        dispatch({
          payload: { authorized: true, token: LocalStorage.getItem('phoenix-atp-store').authToken!, me: data },
          type: '@auth/set',
        })
      }

      return true
    })
  }, [isLoading, isSuccess, data])

  return (
    <AuthContext.Provider value={useMemo(() => ({ dispatch, state }), [state])}>
      {initialized ? children : <FallbackLoader />}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
