import { Suspense } from 'react'
import { Routes as Switch } from 'react-router-dom'
import { BaseLayout } from '../../components/general/layout/BaseLayout'
import { FallbackLoader } from '../../components/general/layout/FallbackLoader'
import { useAuth } from '../../context/Auth'
import { homepage } from './homepage'
import { itemAvailability } from './itemAvailability'
import { itemCommitments } from './itemCommitments'
import { login } from './login'
import { noMatch } from './noMatch'
import { orderDeliverability } from './orderDeliverability'
import { usersSettings } from './settings/usersSettings'

const commonRoutes: JSX.Element[] = []

// NOTE: it's mandatory that the noMatch route must be the last one
const publicRoutesConfiguration: JSX.Element[] = login.concat(commonRoutes).concat(noMatch)

const privateRoutesConfiguration: JSX.Element[] = homepage
  .concat(usersSettings)
  .concat(orderDeliverability)
  .concat(itemCommitments)
  .concat(itemAvailability)
  .concat(noMatch)

export const Routes = () => {
  const {
    state: { authorized },
  } = useAuth()

  if (!authorized) {
    return (
      <Suspense fallback={<FallbackLoader />}>
        <Switch>{publicRoutesConfiguration}</Switch>
      </Suspense>
    )
  }

  return (
    <BaseLayout>
      <Suspense fallback={<FallbackLoader />}>
        <Switch>{privateRoutesConfiguration}</Switch>
      </Suspense>
    </BaseLayout>
  )
}
