import { tryCatch } from 'fp-ts/lib/TaskEither'
import { LocalStorage } from '../../packages/storage/localStorage'
import { AuthenticationError } from '../../types/error'

export const getApiAuthToken = tryCatch(
  async () => LocalStorage.getItem('phoenix-atp-store').authToken,
  (): AuthenticationError => ({
    error: new Error('Cannot fetch the authentication token from local storage'),
    type: 'AuthenticationError',
  })
)
