import { useCallback, useMemo } from 'react'
import { useFetch } from '../../hooks/useFetch'
import { meDecoder } from './me'

export const useSelf = () => {
  const fetch = useFetch()

  const fetchMe = useCallback(
    () =>
      fetch({
        auth: true,
        decoder: meDecoder,
        endpoint: `/api/auth/me`,
        method: 'GET',
      }),
    [fetch]
  )

  return useMemo(() => ({ fetchMe }), [fetchMe])
}
