import { lazy } from 'react'
import { Route } from 'react-router-dom'
import { paths } from '../../../paths'

const UsersSettings = lazy(async () => {
  const { UsersSettings } = await import(
    /* webpackChunkName: "Homepage" */ '../../../../components/pages/private/Settings/UsersSettings'
  )

  return {
    default: UsersSettings,
  }
})

export const usersSettings: JSX.Element[] = [
  <Route element={<UsersSettings />} key={paths('/settings/users')} path={paths('/settings/users')} />,
]
