import React, { Suspense } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { AuthGuard } from './components/general/layout/AuthGuard'
import { FallbackLoader } from './components/general/layout/FallbackLoader'
import { GlobalStyle } from './components/general/layout/GlobalStyle'
import { AuthProvider } from './context/Auth'
import { ConfigProvider } from './context/Config'
import { DialogsProvider } from './packages/context/Dialogs'
import { Routes } from './routes/configuration'
import { Config } from './types/config'
import 'moment/locale/it'

const client = new QueryClient()

type Props = {
  config: Config
}

export const App = ({ config }: Props) => (
  <GlobalStyle>
    <ToastContainer
      closeOnClick
      draggable
      hideProgressBar={false}
      newestOnTop={false}
      pauseOnFocusLoss
      pauseOnHover
      position="top-right"
      rtl={false}
    />
    <ConfigProvider config={config}>
      <QueryClientProvider client={client}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <Suspense fallback={<FallbackLoader />}>
            <DialogsProvider>
              <AuthProvider>
                <AuthGuard>
                  <Routes />
                </AuthGuard>
              </AuthProvider>
            </DialogsProvider>
          </Suspense>
        </BrowserRouter>
      </QueryClientProvider>
    </ConfigProvider>
  </GlobalStyle>
)
