import { isRight } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { fold, none, some } from 'fp-ts/lib/Option'
import { identity, Mixed, OutputOf, success, TypeOf, UnionType } from 'io-ts'

export const weakUnion = <CS extends [Mixed, ...Mixed[]], D>(codecs: CS, defaultValue: D) =>
  new UnionType<CS, TypeOf<CS[number]> | D, OutputOf<CS[number]> | undefined>(
    'WeakUnion',
    (u): u is TypeOf<CS[number]> | D => !!u,
    u =>
      pipe(
        codecs.map(codec => codec.decode(u)),
        decodedValues => {
          const rightValue = decodedValues.find(isRight)

          return rightValue ? some(rightValue) : none
        },
        fold(
          () => success(defaultValue),
          s => s
        )
      ),
    identity,
    codecs
  )
