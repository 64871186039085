import { array } from 'fp-ts'
import { pipe } from 'fp-ts/function'
import { assertIsPlainObject } from '../../functions/assertIsPlainObject'

type QueryParams = Record<
  string,
  boolean | number | string | (boolean | number | string)[] | Record<string, boolean | number | string>
>

export const concatQueryParams = (query: QueryParams) =>
  pipe(
    Object.entries(query),
    array.reduce([] as string[], (searchParams, [key, value]) => {
      if (Array.isArray(value)) {
        return pipe(
          value,
          array.reduce(searchParams, (acc, arrayValue) => [
            ...acc,
            `${encodeURIComponent(`${key}[]`)}=${encodeURIComponent(arrayValue)}`,
          ])
        )
      }

      if (assertIsPlainObject(value)) {
        return pipe(
          Object.entries(value),
          array.reduce(searchParams, (acc, [objKey, objValue]) => [
            ...acc,
            `${encodeURIComponent(`${key}[${objKey}]`)}=${encodeURIComponent(objValue)}`,
          ])
        )
      }

      return [...searchParams, `${encodeURIComponent(key)}=${encodeURIComponent(value)}`]
    }),
    searchParams => searchParams.join('&')
  )
