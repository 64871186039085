import { isLeft } from 'fp-ts/lib/Either'
import { PathReporter } from 'io-ts/lib/PathReporter'
import { nodes, TNode, TGetNodeValue } from './nodes'

export class LocalStorage {
  static getItem<P extends TNode>(node: P): TGetNodeValue<P> {
    const nodeContent = window.localStorage.getItem(node)

    const { makeDecoder } = nodes[node]

    const decoder = makeDecoder()

    const decodedNodeContent = decoder.decode(JSON.parse(nodeContent || '{}'))

    if (isLeft(decodedNodeContent)) {
      // eslint-disable-next-line no-console
      console.error(PathReporter.report(decodedNodeContent))

      throw new Error(`The localStorage node ${nodeContent} contains invalid data`)
    }

    return decodedNodeContent.right
  }

  static removeItem<P extends TNode>(node: P) {
    window.localStorage.removeItem(node)
  }

  static setItem<P extends TNode>(node: P, value: TGetNodeValue<P>) {
    const { makeEncoder } = nodes[node]

    const encoder = makeEncoder()

    window.localStorage.setItem(node, JSON.stringify(encoder(value as any)))
  }
}
