import { CurrentEnvironment } from '../getCurrentEnvironment'

export const getApiEndpoint = (environment: CurrentEnvironment) => {
  if (environment.development) {
    return 'https://www.atp-phoenix.dev'
  }

  if (environment.staging) {
    // FIXME this should be protected through a VPN
    return 'https://cruscotto.staging.idbgroup.it'
  }

  return 'https://cruscotto.idbgroup.it'
}
