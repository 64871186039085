import * as io from 'io-ts'
import { literal, partial, union } from 'io-ts'

export const makeGetPhoenixAtpNodeDecoder = () =>
  partial({
    authToken: io.string,
    role: union([literal('ADMIN'), literal('AGENT'), literal('AREA-MANAGER'), literal('WAREHOUSE')]),
  })

export type GetPhoenixAtpNode = io.TypeOf<ReturnType<typeof makeGetPhoenixAtpNodeDecoder>>
