import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import { getApiEndpoint } from './configurations/apiEndpoint'
import { serverEnvironment } from './configurations/serverEnvironment'
import { Config } from './types/config'
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css'

export default class AppSetup {
  constructor(private appContainerId: string, private config: Config) {}

  setup() {
    const baseUrl = this.config.baseUrl || getApiEndpoint(serverEnvironment)
    const container = document.getElementById('root')
    const root = createRoot(container!)
    root.render(<App config={{ baseUrl }} />)
  }
}

;(window as any).PackageManager = AppSetup
