import Immutable from 'immutable'
import { ImmutableValue } from '../index'
import { IMap } from '../map'

export type TListExtractor<L> = L extends IList<infer E> ? E : never

export interface IList<T extends ImmutableValue> {
  size: number

  butLast(): IList<T>

  clear(): IList<T>

  concat(...collection: T[] | IList<T>[]): IList<T>

  count(predicate: (value: T, key: number, iter: this) => boolean): number
  count(): number

  delete(index: number): IList<T>

  deleteIn(keyPath: (number | string)[]): IList<T>

  entries(): [number, T][]

  equals(maybeEqual: IList<T>): boolean

  every(predicate: (value: T, key: number, iter: this) => boolean): boolean

  filter<S extends T>(predicate: (value: T, index: number, iter: this) => value is S): IList<S>

  filter(predicate: (value: T, index: number, iter: this) => boolean): IList<T>

  filterNot<S extends T>(predicate: (value: T, index: number, iter: this) => value is S): IList<S>

  filterNot(predicate: (value: T, index: number, iter: this) => boolean): IList<T>

  find(predicate: (value: T, key: number, iter: this) => boolean, context?: any, notSetValue?: T): T | undefined

  findIndex(predicate: (value: T, index: number, iter: this) => boolean): number

  findLast(predicate: (value: T, key: number, iter: this) => boolean, context?: any, notSetValue?: T): T | undefined

  findLastIndex(predicate: (value: T, index: number, iter: this) => boolean): number

  first(): T

  get<I extends number, NV extends T>(index: I, notSetValue?: NV): T

  groupBy<G extends string | number | symbol>(
    grouper: (value: T, key: number, iter: this) => G
  ): IMap<{ [key in G]: IList<T> }>

  has(key: number): boolean

  includes(value: T): boolean

  indexOf(value: T): number

  insert(index: number, value: T): IList<T>

  isEmpty(): boolean

  join(separator: string): string

  last(): T

  lastIndexOf(value: T): number

  map<M extends ImmutableValue>(mapper: (value: T, key: number, iter: this) => M): IList<M>

  pop(): IList<T>

  push(...values: T[]): IList<T>

  reduce<R extends ImmutableValue>(
    reducer: (reduction: R, value: T, key: number, iter: this) => R,
    initialReduction: R
  ): R

  reduceRight<R extends ImmutableValue>(
    reducer: (reduction: R, value: T, key: number, iter: this) => R,
    initialReduction: R
  ): R

  reverse(): IList<T>

  shift(): IList<T>

  skip(count: number): IList<T>

  set(index: number, value: T): IList<T>

  setIn(keyPath: (number | string)[], value: ImmutableValue): IList<T>

  setSize(size: number): IList<T>

  slice(startFromIndex: number, toIndex: number): IList<T>

  some(predicate: (value: T, key: number, iter: this) => boolean): boolean

  sort(comparator?: (valueA: T, valueB: T) => number): IList<T>

  sortBy<V extends ImmutableValue>(
    comparatorValueMapper: (value: T, key: number, iter: this) => V,
    comparator?: (valueA: V, valueB: V) => number
  ): IList<T>

  take(size: number): IList<T>

  takeLast(size: number): IList<T>

  toArray(): T[]

  toJS(): T[]

  unshift(...values: T[]): IList<T>

  update(index: number, notSetValue: T, updater: (value: T) => T): IList<T>
  update(index: number, updater: (value: T) => T): IList<T>

  values(): T[]
}

export const List = <P extends ImmutableValue>(param: P[]): IList<P> =>
  Immutable.List(param as any) as unknown as IList<P>

List.isList = <T>(maybeList: unknown): maybeList is IList<T> => Immutable.isList(maybeList)
