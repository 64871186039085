import * as io from 'io-ts'
import { makeGetPhoenixAtpNodeDecoder } from './decoder'
import { makeSetPhoenixAtpNodeEncoder } from './encoder'

export const nodes = {
  'phoenix-atp-store': {
    makeDecoder: makeGetPhoenixAtpNodeDecoder,
    makeEncoder: makeSetPhoenixAtpNodeEncoder,
  },
}

export type TNode = keyof typeof nodes

export type TGetNodeValue<P extends TNode> = io.TypeOf<ReturnType<typeof nodes[P]['makeDecoder']>>
