import { lazy } from 'react'
import { Route } from 'react-router-dom'
import { paths } from '../../paths'

const HomePage = lazy(async () => {
  const { HomePage } = await import(/* webpackChunkName: "Homepage" */ '../../../components/pages/private/HomePage')

  return {
    default: HomePage,
  }
})

export const homepage: JSX.Element[] = [<Route element={<HomePage />} key={paths('/')} path={paths('/')} />]
