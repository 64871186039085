import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/Auth'
import { LocalStorage } from '../../packages/storage/localStorage'
import { paths } from '../../routes/paths'
import { omit } from '../../utils/functions/omit'

export const useLogout = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  return useCallback(async () => {
    const storage = await LocalStorage.getItem('phoenix-atp-store')

    const updatedStorage = omit(storage, ['authToken', 'role'])

    await LocalStorage.setItem('phoenix-atp-store', updatedStorage)

    auth.dispatch({ type: '@auth/unauthorize' })

    navigate(paths('/login'))
  }, [auth, navigate])
}
