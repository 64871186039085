import { error } from './error'
import { log } from './log'
import { warn } from './warn'

type Message = string | number | boolean | Date | undefined

export type Logger = {
  error: (...e: (Message | Error)[]) => void | Promise<void>
  log: (...e: Message[]) => void | Promise<void>
  warn: (...e: Message[]) => void | Promise<void>
}

export const logging: Logger = {
  error,
  log,
  warn,
}
