import { pipe } from 'fp-ts/lib/function'
import { getRefinement, fold, none, some } from 'fp-ts/lib/Option'
import { failure, identity, success, Type } from 'io-ts'

const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export const uuid = new Type<string, string>(
  'Uuid',
  getRefinement((maybeUuid: unknown) => (regex.test(maybeUuid as string) ? some(maybeUuid as string) : none)),
  (anUnknown, c) =>
    pipe(
      anUnknown,
      maybeString => (typeof maybeString === 'string' ? some(maybeString) : none),
      fold(
        () => failure(anUnknown, c, 'The value must be a string.'),
        aString =>
          pipe(
            aString,
            maybeUuid => (regex.test(maybeUuid) ? some(maybeUuid) : none),
            fold(
              () => failure(anUnknown, c, `Invalid Uuid. Received ${anUnknown}`),
              aUuid => success(aUuid)
            )
          )
      )
    ),
  identity
)
